import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import { ElMessage } from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// 视频插件
import VideoPlayer from 'vue-video-player/src'
import 'video.js/dist/video-js.css' 
import 'vue-video-player/src/custom-theme.css'
import md5 from 'js-md5'
// import 'element-plus/dist/index.css'
import '../public/element/dist/index.css'
// 增加进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// import { getToken, getTimestamp, removeToken } from './utils/auth'
import { getToken, getTimestamp } from './utils/auth'

router.beforeEach((to, from, next) => {
    NProgress.start()
    var time = 1000*60*60*24*1
    // 获取当前的token
    const hasToken = getToken()
    // alert(hasToken)
    // 获取上一次登录时间
    const oldTime = getTimestamp()
    
    if (to.meta.requireAuth) {
      // 判断是否需要登录权限
      if (hasToken) {
        var nowTime = new Date().getTime();//当前时间戳
        // 如果当前时间戳> 前面存的时间戳+过期时间 表示已经过期
        var sumTime = parseInt(oldTime) + time;
        if (nowTime > sumTime) {
          // 清除所有信息
          localStorage.clear()
            // token过期
            next({
              path: '/login',
              replace: true
            })
        }
        next()
      } else {
        // 没登录则跳转到登录界面
        next({
          path: '/login',
          replace: true
        })
      }
    } else {
      // 这里做防止跳转回登录页面
      if (hasToken) {
        if (to.path === '/login') {
          next({
            path: '/'
          })
        }
      } else {
        next()
      }
    }
  })
  
  router.afterEach(() => {
    NProgress.done()
  })

const app = createApp(App);
app.config.globalProperties.$wx_appid = 'wxd356189340b8fb09' // 演示
// app.config.globalProperties.$wx_appid = 'wx7508e6cb4113d858' // 生产
app.use(store);
app.use(router);
app.use(ElementPlus,{locale:zhCn})
app.use(VideoPlayer)
app.config.globalProperties.$md5 = md5
app.config.globalProperties.$public_key = '-----BEGIN PUBLIC KEY-----' +
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCCHbwZCOhcm/pIyKXC1DO/Vdd5' +
    '6jOFBvZXzQaxmBk25OGPMJ2+bVS+/QWQ3oDZ6v1MUVTmI8gUXTcvdqdq7bJNiuX7' +
    'qhL9UKFDKlEu8Um41BYXq8Eu4GByw91jHbLoVlTC8+6uZgFIuBY3ibPFgld0dBNl' +
    'e3IS4yQLBPeILYPqHQIDAQAB' +
'-----END PUBLIC KEY-----'
app.config.globalProperties.$authkey = '0a2ee68f79089ab75f8a7f1983bcb914'
app.config.globalProperties.$ElMessage = ElMessage
app.mount('#app')
